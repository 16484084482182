import Swiper from 'swiper';
import { Navigation, Thumbs, Mousewheel } from 'swiper/modules';

export default function videoSwiper() {
    const communitySwiper = document.querySelectorAll('.section-list-3 .overlay');
    if (!communitySwiper) return;
    communitySwiper.forEach((el) => {
      const slider = el.querySelector('.thumb-swiper-popup');
      const slider2 = el.querySelector('.swiper-popup');

      var swiper = new Swiper(slider, {
        spaceBetween: 10,
        direction: 'horizontal',
        slidesPerView: 4
      });

      var swiper2popup = new Swiper(slider2, {
        modules: [Thumbs],
        spaceBetween: 10,
        slidesPerView: 1,
        thumbs: {
          swiper: swiper
        }
      });
    });

  var swiper = new Swiper('.thumb-swiper', {
    modules: [Navigation, Mousewheel],
    spaceBetween: 10,
    slidesPerView: 3.5,
    direction: 'vertical',
    mousewheel: true,
    breakpoints: {
      0: {
        direction: 'horizontal',
        slidesPerView: 2.5
      },
      768: {
        direction: 'vertical',
        slidesPerView: 3.5
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });

  var swiper2 = new Swiper('.videos-swiper', {
    modules: [Thumbs],
    spaceBetween: 10,
    thumbs: {
      swiper: swiper
    }
  });

  let slides = document.querySelectorAll('.videos-swiper .swiper-slide');
  slides.forEach((item) => {
    if (item.querySelector('.swiper-slide__preview')) {
        item.querySelector('.swiper-slide__preview').addEventListener('click', function () {
        slides.forEach((video) => {
          video.querySelector('video').pause();
        })
        item.querySelector('.swiper-slide__preview').style.display = 'none';
        item.querySelector('video').play();
      });
    }
  });
  let slidesthumb = document.querySelectorAll('.thumb-swiper .swiper-slide');
  slidesthumb.forEach((item) => {
    item.addEventListener('click', function () {
      slides.forEach((video) => {
        video.querySelector('video').pause();
      })
    })
  });
}
