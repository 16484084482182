export default function example() {
  return 1;
}

jQuery(document).ready(function ($) {
  $('#your-select-id').change(function () {
    var selectedCategory = $(this).val();
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'filter_products',
        category: selectedCategory
      },
      success: function (response) {
        var result = $.parseJSON(response);
        if (result.success) {
          $('.section-products__list').html(result.html);
        } else {
          console.log('Error');
        }
      }
    });
  });
  
  $('.section-list-3__block').click(function () {
    var popupId = $(this).data('popup-id');
    $('[data-popup-id="' + popupId + '"]').fadeIn();
  });

  $('.close-popup').click(function () {
    var popup = $(this).closest('.overlay');
    popup.fadeOut();
  });

  $(document).mouseup(function (e) {
    var popup = $('.popup');
    if (e.target != popup[0] && popup.has(e.target).length === 0) {
      $('.overlay').fadeOut();
    }
  });
});
