import example from './modules/example';
import navigation from './modules/navigation';
import videoSwiper from './modules/swiper';
import select2script from './modules/select2';
import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  select2script();
  example();
  navigation();
  videoSwiper();
});